import React, { useState } from 'react'
import { AuthGetResponse, PlaidError } from 'plaid'
import api from '../api'
import { usePlaid } from '../hooks'
import { Navigate } from 'react-router'

export const PlaidIncome = ({ }) => {
  const [plaidError, setPlaidError] = useState<PlaidError | string>()
  const [testSuccess, setTestSuccess] = useState(false)

  const { ready, open } = usePlaid({
    onSuccess: (public_token, metadata) => {
      const query = new URLSearchParams({ public_token })
      api.get(`/plaid/income?${query.toString()}`)
        .then(() => setTestSuccess(true))
        .catch(setPlaidError)
    },
    onError(err) {
      console.error(`plaid error`, err)
      setPlaidError(err)
    },
    onExit(reason) {
      console.error('plaid exit', reason)
      if (reason) {
        setPlaidError(reason)
      } else {
        // onManual()
      }
    }
  })

  if (testSuccess) {
    return <Navigate to={`/report`} />
  }

  return <div>
    {plaidError ? <div>
      {/* <div className="alert alert-danger">
          <p>There was an error linking your autopay account. You may retry or manually enter your autopay details.</p>
          <p><b>Error:</b>&nbsp;{plaidErrorToString(plaidError)}</p>
        </div>
        <div className="buttons" style={{ textAlign: 'left' }}>
          <button type="button" className="btn btn-primary" onClick={() => { setPlaidError(undefined); open() }}>Retry</button>
          <button type="button" className="btn btn-secondary" onClick={onManual}>Enter Manually</button>
        </div> */}
      Plaid Error
    </div> : <div className="buttons" style={{ textAlign: 'left' }}>
      <button
        type="button"
        disabled={!ready}
        className="btn btn-primary"
        onClick={() => open()}>Verify Income</button>
    </div>}
  </div>
}
