import React, { useEffect, useState } from "react"
import { PlaidLinkOnSuccess, usePlaidLink } from "react-plaid-link"
import api from '../api'

type UsePlaidProps = {
  onSuccess: PlaidLinkOnSuccess
  onExit: (reason: any) => void
  onEvent?: (event: any) => void
  onError: (error: any) => void
}

export const usePlaid = ({
  onSuccess,
  onExit,
  onEvent,
  onError
}: UsePlaidProps) => {
  const [token, setToken] = useState<string>()

  useEffect(() => {
    api.get(`/plaid/create_link_token`).then(({ link_token }) => {
      setToken(link_token)
    }).catch(onError)
  }, [])

  const { open, ready, error } = usePlaidLink({
    token,
    onSuccess,
    onExit,
    onEvent
  })

  return { open, ready, error }
}

export default usePlaid