import QRCode from "react-qr-code"
import { useLocalStorage } from '../hooks'
import { useEffect, useState } from "react"
import api from "../api"
import { toast } from "react-toastify"
import { Spinner } from "../components"
import { access } from "fs"
import { Navigate } from "react-router"

const poll = ({ maxRetries, action }) => {
  return new Promise((resolve, reject) => {
    let retries = 0

    const go = () => {
      console.log({ retries })
      action().then(resolve).catch(ex => {
        if (retries > maxRetries) {
          return reject('exceeded max retries')
        } else {
          retries++
          setTimeout(() => go(), 4000)
        }
      })
    }

    go()
  })
}

export const ReportPage = () => {
  const [accessToken, setAccessToken, clearAccessToken] = useLocalStorage('t', undefined)
  const [report, setReport] = useState(undefined)
  const [polling, setPolling] = useState(false)
  const [error, setError] = useState('')

  useEffect(() => {
    if (accessToken && !polling && !report && !error) {
      setPolling(true)
      poll({
        maxRetries: 10,
        action: () => api.get('/applicants/report')
      }).then((res: any) => {
        setReport(res.data)
      }).catch(ex => { setError(ex) })
        .finally(() => setPolling(false))
    }
  }, [])

  if (!accessToken) {
    return <Navigate to="/" />
  }

  return <div>
    <div>
      <h4>Payroll</h4>
      {polling ? <div>
        <Spinner />
        <div>Waiting for results...</div>
      </div> : null}
      {error ? <div className="alert alert-danger">{error}</div> : null}
      {report?.items?.length ? <div>
        {report?.items?.map(item => <div key={item.item_id} className="mb-3">
          <div>Institution: {item.institution_name}</div>
          {item.accounts?.map(acc => <div key={acc.account_id}>
            <div>Pay Rate: {acc.rate_of_pay.pay_rate}</div>
            <div>Amount: {acc.rate_of_pay.pay_amount}</div>
          </div>)}
          <h6>Paystubs</h6>
          {item.payroll_income?.map(pi => pi.pay_stubs?.map(stub => {
            const { pay_frequency, pay_date, pay_amount } = stub.pay_period_details
            return <div key={stub.document_id}>
              {pay_frequency} {pay_date} {pay_amount}
            </div>
          }))}
        </div>)}
        <h4>Share</h4>
        <QRCode
          // size={256}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={`http://localhost:3000/testqrcode?${accessToken}`}
          viewBox={`0 0 256 256`}
        />
      </div> : null}
    </div>
  </div>
}