import React, { useContext, useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import api from '../api'
import { VerifyEmail, VerificationTokenPayload } from '../components/verify-email'
import { useSearchParams } from 'react-router-dom'

const CheckLoginState = () => {
  // const { state: { registration: { installer } } } = useContext(AppContext)
  const [user, setUser] = useState()
  const [params, ] = useSearchParams()

  try {
    const t = params.get('t')
    const payload = jwtDecode(t) as VerificationTokenPayload
    const { _id } = payload

    if (!user) {
      return <VerifyEmail
        verificationToken={t}
        payload={payload}
      />
    }

  } catch (ex) {
    return <div>
      <div className="alert alert-danger">
        Error initializing page: {ex.message}
      </div>
    </div>
  }
}

export const VerifyEmailPage = () => {
  return <CheckLoginState />
}

export default VerifyEmailPage
