import React, { useState, useEffect } from 'react'
import './App.css'
import { RouterProvider } from 'react-router-dom'
import { FaCheck, FaCopy, FaSpinner, FaRedo } from 'react-icons/fa'
import { toast } from 'react-toastify'
import ApplicantForm from './components/applicant-form'
import { router } from './router'

const { REACT_APP_API_URL } = process.env

const App = () => {
  return <RouterProvider router={router} />
}

export default App
