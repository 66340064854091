import React, { useContext, useEffect, useState } from 'react'
import api from '../api'
import { useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import Spinner from './spinner'
import { PlaidIncome } from './plaid-income'
import { useLocalStorage } from '../hooks'

export type VerificationTokenPayload = {
  _id: string
  email: string
}

type VerifyInformationProps = {
  verificationToken: string
  payload: VerificationTokenPayload
}

export const VerifyEmail = ({ verificationToken, payload }: VerifyInformationProps) => {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState(false)
  const navigate = useNavigate()
  const [accessToken, setAccessToken, clearAccessToken] = useLocalStorage('t', undefined)

  const handleError = (ex: any) => {
    if (ex.error === 'jwt expired') {
      setError('This link has expired. Please restart the process.')
    } else {
      toast.error(JSON.stringify(ex))
      setError(ex.message || ex.error || ex)
    }
  }

  useEffect(() => {
    if (!accessToken && !loading && !error) {
      setLoading(true)
      api.get(`/applicants/verify-email?t=${verificationToken}`).then(res => {
        setAccessToken(res.accessToken)
        setSuccess(true)
      }).catch(ex => handleError(ex)).finally(() => setLoading(false))
    }
  }, [accessToken, loading, error])

  return <div className="email-verification">
    {loading ? <>
      <Spinner />
      <p>Verifying Email...</p>
    </> : null}
    {error ? <div className="alert alert-danger">{error}</div> : <></>}
    {success ? <>
      <div className="alert alert-success">Email address successfully verified!</div>
      {/* <p>Stand by for next steps...</p> */}
      <PlaidIncome />
    </> : null}
  </div>
}

export default VerifyEmail
