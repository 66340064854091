
class ApiClient {
  private endpoint?: string;

  constructor(opts) {
    this.endpoint = opts.endpoint
  }

  private _fetch(url: string, opts: RequestInit): Promise<any> {
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem('t')
    }

    return new Promise((resolve, reject) => {
      fetch(url, {
        ...opts,
        headers
      })
        .then(async (res) => {
          let json
          let text

          try {
            text = await res.text()
            json = JSON.parse(text)
          } catch (ex) {
            console.log('error parsing JSON, text:', text)
            return reject(ex.message)
          }

          // console.log({ url, json, status: res.status })

          if (res.status >= 200 && res.status < 300) {
            return resolve(json)
          } else {
            json.status = res.status
            return reject(json)
          }
        })
        .catch((ex) => {
          reject(ex)
        })
    })
  }

  public authenticatedFetch(path: string, opts: any): Promise<any> {
    return fetch(this.endpoint + path, {
      ...opts
    })
  }

  async get(url: string, params?: Record<string, any>): Promise<any> {
    const query = new URLSearchParams(params)
    let _url = url
    if (params) {
      _url += `?${query.toString()}`
    }

    return this._fetch(this.endpoint + _url, {
      method: 'GET',
    })
  }

  async update(url: string, body, method): Promise<any> {
    return this._fetch(this.endpoint + url, {
      method,
      body: JSON.stringify(body),
    })
  }

  post(url: string, body?: object): Promise<any> {
    return this.update(url, body, 'POST')
  }

  put(url: string, body?: object): Promise<any> {
    return this.update(url, body, 'PUT')
  }

  patch(url: string, body?: object): Promise<any> {
    return this.update(url, body, 'PATCH')
  }

  delete(url: string): Promise<any> {
    return this._fetch(this.endpoint + url, {
      method: 'DELETE',
    })
  }
}

const api = new ApiClient({
  endpoint: `${process.env.REACT_APP_API_ORIGIN || ''}/api`
})

export default api

