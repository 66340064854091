import React, { useState } from 'react'
import api from '../api'

const Intro = () => {
  return <div className="intro">
    <div className="fs-4">
      Your Ultimate Solution for Affordable, Secure, and Effortless Rental Applications in Colorado!
    </div>
    
    <div className="hl1">
      Tired of shelling out $50-100 for each rental application? Say goodbye to costly
      application fees and welcome PortableRentalApp.com into your rental journey.
    </div>

    <div>
      <div className="section-label">Cost-Effective</div>
      <span>
        With PortableRentalApp.com, you can apply to multiple rentals using just one
        low-cost rental application. Save your hard-earned money while exploring various
        rental options in Colorado.
      </span>
    </div>
    <div>
      <div className="section-label">Legal Compliance</div>
      <span>
        Colorado landlords are legally obligated to accept our rental application
        for every rental unit. Rest assured, your application will meet all legal
        requirements seamlessly.
      </span>
    </div>
    <div>
      <div className="section-label">Security</div>
      <span>
        Your security matters to us. PortableRentalApp.com offers a secure portal to store all
        your rental application information. Powered by TransUnion, the most trusted name in
        credit, background, and eviction history searches, your data is in safe hands.
      </span>
    </div>
    <div>
      <div className="section-label">Efficiency</div>
      <span>
        Don't waste precious time filling out multiple applications for different
        landlords. Our platform streamlines the process, allowing you to apply
        swiftly and efficiently to your desired rental properties.
      </span>
    </div>
    <div>
      <div className="section-label">Ease of Use</div>
      <span>
        Updating your information for future applications has never been easier. Your
        data is securely saved on our platform, ready to be accessed and modified whenever needed.
      </span>
    </div>

    <div className="hl1">
      Don't let the hassle and expense of traditional rental applications hold you back.
      Join PortableRentalApp.com today and unlock a hassle-free, cost-effective, and secure
      rental application experience tailored to your needs.
    </div>
  </div>
}

export const ApplicantForm = () => {
  const [loading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    streetAddress: '',
    email: '',
    city: '',
    state: '',
    zip: '',
  })

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    setLoading(true)
    api.post('/applicants', formData).then(() => {
      setSubmitted(true)
    }).catch(ex => console.error(ex)).finally(() => setLoading(false))
  }

  // Update state on input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  if (submitted) {
    return <div className="alert alert-success">
      Check your email to proceed
    </div>
  }

  return (
    <div>
      <h2>PortableRentalApplication.com</h2>
      <Intro />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>First Name</label>
          <input
            type="text"
            className="form-control"
            name="firstname"
            value={formData.firstname}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Last Name</label>
          <input
            type="text"
            className="form-control"
            name="lastname"
            value={formData.lastname}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Email</label>
          <input
            type="email"
            className="form-control"
            name="email"
            value={formData.email}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>Street Address</label>
          <input
            type="text"
            className="form-control"
            name="streetAddress"
            value={formData.streetAddress}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>City</label>
          <input
            type="text"
            className="form-control"
            name="city"
            value={formData.city}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>State</label>
          <input
            type="text"
            className="form-control"
            name="state"
            value={formData.state}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label>ZIP Code</label>
          <input
            type="text"
            className="form-control"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
          />
        </div>
        <div className="buttons">
          <button className="btn btn-primary" type="submit">Submit</button>
        </div>
      </form>
    </div>
  )
}

export default ApplicantForm
