import React, { useReducer, FC, useEffect, useState, useContext } from 'react'
import {
  Navigate,
  Route,
  RouterProvider,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  useNavigate
} from 'react-router-dom'
import { VerifyEmailPage, ReportPage } from './pages'
import ApplicantForm from './components/applicant-form'
import { useLocalStorage } from './hooks'

const RouterWrap = () => {
  const [accessToken, ] = useLocalStorage('t', undefined)
  const navigate = useNavigate()

  useEffect(() => {
    if (accessToken) {
      navigate('/report')
    }
  }, [])

return <div className="page">
    <div className="content">
      <Routes>
        <Route path="/verify-email" element={<VerifyEmailPage />} />
        <Route path="/report" element={<ReportPage />} />
        <Route path="*" element={<ApplicantForm />} />
      </Routes>
    </div>
  </div >
}

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={<RouterWrap />} />
  )
)

export default router